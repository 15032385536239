
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(updateLocale)
dayjs.extend(updateLocale)
dayjs.locale('pt-br')
dayjs.tz.setDefault('America/Sao_Paulo')


dayjs.updateLocale("pt-br")

export default dayjs
