import { defineNuxtPlugin } from '#app'
import Vue3Toastify, { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Vue3Toastify, {
    autoClose: 3500,
    // false, // 3000,
    theme: 'dark',
    // position: 'top-center',
    position: 'top-right',
    pauseOnHover: true
  });

  return {
    provide: { toast },
  };
});
