// create nuxt3 filters global
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(() => {
  // make globally available on nuxt app as useFilters()
  // nuxt.config.globalProperties.$filters = filters
  const dayjs = useDayjs()
  // const dayjs = useNuxtApp().$dayjs

  const filters = {
    friendlyEventStatus (value) {
      switch (value) {
        case 'event_status_draft':
          return 'rascunho'
        case 'event_status_live':
          return 'publicado'
        case 'event_status_cancelled':
          return 'cancelado'
        case 'event_status_finished':
          return 'finalizado'
        default:
          return 'rascunho'
      }
    },

    eventStatusColorClasses (value) {
      switch (value) {
        case 'event_status_draft':
          return 'bg-yellow-300'
        case 'event_status_live':
          return 'bg-green-300'
        case 'event_status_cancelled':
          return 'bg-gray-300'
        case 'event_status_finished':
          return 'bg-gray-700'
        default:
          return 'bg-gray-300'
      }
    },

    paymentStatusColorClasses (value) {
      switch (value) {
        case 'payment_status_paid':
          return 'text-green-500 dark:text-green-300'
        case 'payment_status_cancelled':
          return 'text-red-500 dark:text-red-300'
        case 'payment_status_waiting':
          return 'text-gray-500 dark:text-gray-300'
      }
    },
    bookEntryColorClasses (value) {
      switch (value) {
        case 'deposit':
          return 'text-green-500 dark:text-green-300'
        case 'deposit_late':
          return 'text-green-500 dark:text-green-300'
        case 'withdrawal':
          return 'text-yellow-500 dark:text-yellow-300'
        case 'deposit_chargeback':
          return 'text-green-500 dark:text-green-300'
        case 'chargeback':
          return 'text-red-500 dark:text-red-300'
        default:
          return 'text-gray-500 dark:text-gray-300'
      }
    },

    friendlyPaymentMethod (value) {
      switch(value) {
        case 'payment_method_gn':
          return 'Cartão de Crédito'
        case 'payment_method_stripe':
          return 'Cartão de Crédito'
        case 'payment_method_pix':
          return 'Pix'
        default:
          return 'Pix'
      }
    },

    friendlyPaymentStatus (value) {
      switch(value) {
        case 'payment_status_waiting':
          return 'Aguardando pagamento'
        case 'payment_status_paid':
          return 'Pago'
        case 'payment_status_cancelled':
          return 'Cancelado'
        case 'payment_status_expired':
          return 'Expirado'
        default:
          return ''
      }
    },

    asCurrency (val) {
      if (!val) {
        return "R$ 0,00"
      }
      const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
      return formatter.format(val)
    },

    asPercentage (val) {
      if (!val) {
        return " "
      }
      const formatter = new Intl.NumberFormat('pt-BR', { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 1 })
      return formatter.format(val)
    },

    friendlyDate (val) {
      try {
        return dayjs(val).format('DD/MM/YYYY HH:mm')
      } catch(_) { return val }
    },

    friendlyDateShort (val) {
      return dayjs(val).format('D [de] MMM HH[h]')
      // try {
      // } catch(_) { return val }
    },

    friendlyDateAdmin (val) {
      return dayjs(val).format('ddd[,] D [de] MMM [as] HH[h]mm')
      // try {
      // } catch(_) { return val }
    },

    truncate(str, num) {
      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    },
    shortDateTime (val) {
      return dayjs(val).fromNow()
      // format('ddd[,] D [de] MMM [as] HH[h]mm')
    },
    friendlyRole (role) {
      switch (role) {
        case 'role_admin':
          return 'admin'
        case 'role_scanner':
          return 'porta/scanner'
        default:
          return 'normal'
      }
    },
    friendlyFileType (value) {
      switch (value) {
        case 'file_type_image':
          return 'imagem'
        case 'file_type_banner':
          return 'banner/post'
        case 'file_type_video_square':
          return 'video quadrado'
        case 'file_type_video_wide':
          return 'video horizontal'
        case 'file_type_video_vertical':
          return 'video vertical'
        case 'file_type_image_square':
          return 'foto quadrada'
        case 'file_type_image_wide':
          return 'foto horizontal'
        case 'file_type_image_vertical':
          return 'foto vertical'
        default:
          return value
      }
    },
    friendlyCampaignStatus (value) {
      switch (value) {
        case 'status_draft':
          return 'rascunho'
        case 'status_ready':
          return 'enviando'
        case 'status_sent':
          return 'enviada'
        default:
          return value
      }
    },

    friendlyCampaignTarget (value) {
      switch (value) {
        case 'target_all':
          return 'quem já comprou meus eventos'
        case 'target_event':
          return 'quem comprou um evento específico'
        default:
          return value
      }
    },
  }

  return {
    provide: {
      filters: filters
    }
  }




  // nuxt.hook('app:useFilters', (app) => {
  //   // app.use(...)
  //   // app.config.globalProperties.$filters = filters
  // })

})
