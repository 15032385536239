<template>
  <div>
    <div @click.stop.prevent="onClick" @mouseover="onMouseOver" @mouseleave="onMouseLeave" :class="['absolute bottom-0 left-0 transition-all bg-yellow-300 text-black p-2 rounded z-50', { 'opacity-20': !isHovering }]">
      <svg class="w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
      </svg>
    </div>
    <div @click.stop.prevent="onClick" @mouseover="onMouseOver" @mouseleave="onMouseLeave" :class="['fixed top-0 w-full h-2 transition-all bg-yellow-300 z-50', { 'opacity-20': !isHovering }]"></div>
    <div @click.stop.prevent="onClick" @mouseover="onMouseOver" @mouseleave="onMouseLeave" :class="['fixed bottom-0 w-full h-2 transition-all bg-yellow-300 z-50', { 'opacity-20': !isHovering }]"></div>

    <ModalMenu :show="isModalOpened" @onCloseModal="isModalOpened = false">
      <h4 class="text-xl font-bold mb-4">Staging</h4>
      <p class="text-xl">ESSE AMBIENTE É DE TESTES DA PLATAFORMA</p>
    </ModalMenu>
  </div>
</template>

<script setup>
const isHovering = ref(false)
const isModalOpened = ref(false)

const onMouseOver = () => {
  isHovering.value = true
}

const onMouseLeave = () => {
  isHovering.value = false
}

const onClick = () => {
  isModalOpened.value = true
}
</script>
